/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
// import slugify from "react-slugify"
import "normalize.css"

// import { JsonCreatorPlugin } from "gatsby-tinacms-json"
// import { withPlugin } from "tinacms"



import Navbar from './navbar'
import Footer from './footer'

// const CreateUseCaseButton = new JsonCreatorPlugin({
//   label: "New Use Case",
//   filename(form) {
//     let slug = slugify(form.title.toLowerCase())
//     return `content/uses/${slug}.json`
//   },
//   fields: [
//     { name: "title", label: "Title", component: "text", required: true },
//     { name: "path", label: "Path", component: "text", required: true },
//   ],
//   data(form) {
//     return {
//       title: form.title,
//       path: `/use-cases/${form.path}`,
//     }
//   },
// })


const StyledMain = styled.main`
  flex: 1 100%;

`

const Layout = ({ children, reverseNav, full, mainStyles }) => {
  const data = useStaticQuery(graphql`
    query SiteLinksQuery {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
          sideMenuLinks {
            link
            name
          }
          title
        }
      }
    }
  `)

  return (
    <Styled.root>
      
      <Navbar reverseNav={reverseNav} menuLinks={data.site.siteMetadata.menuLinks} sideMenuLinks={data.site.siteMetadata.sideMenuLinks}/>

      { children && <StyledMain sx={mainStyles} style={{overflow: 'hidden'}}>{children}</StyledMain> }
      
      <Footer siteTitle={data.site.siteMetadata.title} />
      
    </Styled.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// export default withPlugin(Layout, [CreateUseCaseButton])
export default Layout
